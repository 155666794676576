import React from "react";

import Layout from "../layouts";

const NotFoundPage = () => (
  <Layout title="404: Not found">
    <h1 style={{ margin: "100px auto" }}>404: Page not found</h1>
  </Layout>
);

export default NotFoundPage;
